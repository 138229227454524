.layout-transparent-topbar {
    // --topbar-bg-color: transparent;
    // --topbar-border-color: var(--surface-border);
    // --topbar-box-shadow: none;

    // --topbar-item-text-color: var(--text-color);
    // --topbar-item-text-color-secondary: var(--text-color-secondary);
    // --topbar-input-bg-color:var(--surface-border);

    // --topbar-popup-item-bg-color:var(--surface-card);
    // --topbar-popup-item-shadow: 0 5px 12px 6px #00000017;

    --topbar-bg-color: #ffffff;
    --topbar-border-color: #d8dadc;
    --topbar-box-shadow: 0 10px 40px 0 rgba(#293241, 0.06);

    --topbar-item-text-color: #293241;
    --topbar-item-text-color-secondary: #545b67;
    --topbar-input-bg-color:var(--surface-ground);

    --topbar-popup-item-bg-color:#ffffff;
    --topbar-popup-item-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}
