/* You can add global styles to this file, and also import other style files */

@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/primeicons/primeicons.css';
@import '../../../node_modules/primeflex/primeflex.scss';
@import '../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

/* Override */
.p-treetable .p-treetable-tbody > tr > td,
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0 1rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled {
  padding-top: 1px;
  padding-left: 4.5px;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  padding-top: 1px;
  padding-left: 4.5px;
}

a:any-link {
  text-decoration: none;
}

body {
  color: var(--text-color);
}
